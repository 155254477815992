import { IMonths } from './IPlannedInspection';

export interface IPlannedDetailInspection {
    id: string;
    siteReference: string;
    clientNumber: string;
    applicationDate: string;
    activity: string;
    siteName: string;
    city: string;
    inspections: IMonths;
}

export const emptyPlannedDetailInspection = (): IPlannedDetailInspection => ({
    id: '',
    siteReference: '',
    clientNumber: '',
    applicationDate: new Date().toString(),
    activity: '',
    siteName: '',
    city: '',
    inspections: {
        jan: '',
        feb: '',
        mar: '',
        apr: '',
        may: '',
        jun: '',
        jul: '',
        aug: '',
        sep: '',
        oct: '',
        nov: '',
        dec: '',
        total: 0,
    },
});
