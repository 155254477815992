////import 'material-design-icons-iconfont/dist/material-design-icons.css';
////import '@mdi/font/css/materialdesignicons.css';
import axios from 'axios';
import moment from 'moment';
import Vue from 'vue';
import VueRouter from 'vue-router';
// eslint-disable-next-line import/no-named-as-default
import VuexPersistence from 'vuex-persist';

import AuthenticatedInterceptor from './secondary/error/AuthenticatedInterceptor';
import GuestInterceptor from './secondary/error/GuestInterceptor';
import { RestConfigurationRepository } from './secondary/restConfiguration/RestConfigurationRepository';
import { RestHumanSupervisionRepository } from './secondary/restHumanSupervision/RestHumanSupervisionRepository';
import { RestInspectionRepository } from './secondary/restInspection/RestInspectionRepository';
import RestQuoteRepository from './secondary/restQuote/RestQuoteRepository';
import { RestRemoteMaintenanceRepository } from './secondary/restTroubleshooting/RestRemoteMaintenanceRepository';
import { RestTroubleshootingMaintenanceRepository } from './secondary/restTroubleshooting/RestTroubleshootingMaintenanceRepository';
import { RestVideoPatrolRepository } from './secondary/restVideo/RestVideoPatrolRepository';
import { RestWorkSiteRepository } from './secondary/restWorkSite/RestWorkSiteRepository';

import { Routes } from '@/domain/Routes';
import vuetify from '@/plugins/vuetify';
import { App } from '@/primary/app';
import CguBus from '@/primary/components/footer/CguBus';
import MenuBus from '@/primary/components/menu/MenuBus';
import MenuMobileBus from '@/primary/components/menu/MenuMobileBus';
import NavigationButtonBus from '@/primary/components/ModifyContactModal/NavigationButtonBus';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import TabsNavigationBus from '@/primary/components/navbar/TabsNavigationBus';
import TabsNumberNavigationBus from '@/primary/components/navbar/TabsNumberNavigationBus';
import ModifyInformationsContactBus from '@/primary/pages/Instructions/ModifyInformationsContactBus';
import { booleanFormat } from '@/primary/utils/BooleanFormat';
import { dateFormat, dateFormatShort } from '@/primary/utils/Filters/DateFormat';
import { hourFormat } from '@/primary/utils/Filters/HourFomat';
import { Responsive } from '@/primary/utils/Responsive';
import ToastBus from '@/primary/utils/ToastBus';
import { router } from '@/routes';
import { RestInformationBandRepository } from '@/secondary/informationBand/RestInformationBandRepository';
import { RestAccountRepository } from '@/secondary/restAccount/RestAccountRepository';
import { RestContactRepository } from '@/secondary/restContact/RestContactRepository';
import { RestEventRepository } from '@/secondary/restEvent/RestEventRepository';
import { RestFileRepository } from '@/secondary/restFile/RestFileRepository';
import { RestSiteRepository } from '@/secondary/restSite/RestSiteRepository';
import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';
import { RestTimeGridRepository } from '@/secondary/restTimeGrid/RestTimeGridRepository';
import { RestUserRepository } from '@/secondary/restUser/RestUserRepository';
import { RestVideoCreditRepository } from '@/secondary/restVideo/RestVideoCreditRepository';
import { RestVideoInstructionRepository } from '@/secondary/restVideo/RestVideoInstructionRepository';
import { LocaleCallListStoreRepository } from '@/secondary/store/callListStore/LocaleCallListStoreRepository';
import { LocaleStoreRepository } from '@/secondary/store/localeStore/LocaleStoreRepository';
import { LocalRightsStoreRepository } from '@/secondary/store/rights/LocalRightsStoreRepository';
import { createStore } from '@/secondary/store/StoreVuex';
import { RestTermsOfServiceRepository } from '@/secondary/termsOfService/RestTermsOfServiceRepository';
import { RestUserManagementRepository } from '@/secondary/userManagement/RestUserManagementRepository';

Vue.config.productionTip = true;
[VueRouter].forEach(plugin => Vue.use(plugin));

Vue.prototype.moment = moment;

Vue.filter('dateFormat', dateFormat);
Vue.filter('dateFormatShort', dateFormatShort);
Vue.filter('booleanFormat', booleanFormat);
Vue.filter('hourFormat', hourFormat);

const store = createStore();

//////////////////// instantiation de bus et toaster ///////////////
const vueBus = new Vue();
const toastBus = new ToastBus(vueBus);
const storeRepository = new LocaleStoreRepository(store);

//////////////////// instantiation des intercepotors ///////////////
const guestInterceptor = new GuestInterceptor(toastBus);
const authenticatedInterceptor = new AuthenticatedInterceptor(toastBus, storeRepository);

new VuexPersistence({
    key: 'delta',
    storage: window.localStorage,
    modules: ['localStorageModule'],
}).plugin(store);

router.beforeEach((to, from, next) => {
    if (
        to.name !== Routes.LoginPage &&
        to.name !== Routes.ForgottenPasswordPage &&
        to.name !== Routes.ActivateAccountPage &&
        !store.getters['localStorageModule/user'].userToken
    )
        next({ name: Routes.LoginPage });
    else next();
});

const responsive = new Responsive(window);

//////////////////// instantiation des bus ///////////////
const menuBus = new MenuBus(vueBus);
const menuMobileBus = new MenuMobileBus(vueBus);
const pageNameBus = new PageNameBus(vueBus);
const navigationButtonBus = new NavigationButtonBus(vueBus);
const tabsNavigationBus = new TabsNavigationBus(vueBus);
const tabsNumberNavigationBus = new TabsNumberNavigationBus(vueBus);
const modifyInformationsContactBus = new ModifyInformationsContactBus(vueBus);
const cguBus = new CguBus(vueBus);

//////////////////// instantiation des repository non relatifs � la config ///////////////
const callListStoreRepository = new LocaleCallListStoreRepository(store);
const rightRepository = new LocalRightsStoreRepository(store);

//////////////////// declaration des repository relatifs � la config ///////////////
let accountRepository: RestAccountRepository;
let eventRepository: RestEventRepository;
let contactRepository: RestContactRepository;
let timeGridRepository: RestTimeGridRepository;
let siteRepository: RestSiteRepository;
let videoCreditRepository: RestVideoCreditRepository;
let videoInstructionRepository: RestVideoInstructionRepository;
let videoPatrolRepository: RestVideoPatrolRepository;
let troubleshootingMaintenanceRepository: RestTroubleshootingMaintenanceRepository;
let remoteMaintenanceRepository: RestRemoteMaintenanceRepository;
let inspectionRepository: RestInspectionRepository;
let workSiteRepository: RestWorkSiteRepository;
let quoteRepository: RestQuoteRepository;
let configurationRepository: RestConfigurationRepository;
let termsOfServiceRepository: RestTermsOfServiceRepository;
let humanSupervisionRepository: RestHumanSupervisionRepository;
let userRepository: RestUserRepository;
let fileRepository: RestFileRepository;
let userManagementRepository: RestUserManagementRepository;
let informationBandRepository: RestInformationBandRepository;

const InitialiseApp = (config: any) => {
    //////////////////// configuration Axios Guest ///////////////
    const axiosGuestInstance = axios.create({
        baseURL: config.backUrl,
    });

    axiosGuestInstance.interceptors.response.use(
        response => response,
        error => guestInterceptor.onReject(error)
    );

    //////////////////// configuration Axios Authenticated ///////////////
    const axiosAuthenticatedInstance = axios.create({
        baseURL: config.backUrl,
    });

    axiosAuthenticatedInstance.interceptors.request.use(
        (config: any) => {
            let accessToken = '';
            if (store.getters['localStorageModule/user'].userToken) {
                accessToken = store.getters['localStorageModule/user'].userToken;
            }
            if (accessToken != undefined && accessToken.length > 0) {
                config.headers = Object.assign(
                    {
                        Authorization: RestThemeRepository.getNameOfSite() + ` ${accessToken}`,
                    },
                    config.headers
                );
            }
            return config;
        },
        (error: any) => {
            return Promise.reject(error);
        }
    );

    axiosAuthenticatedInstance.interceptors.response.use(
        response => response,
        error => authenticatedInterceptor.onReject(error)
    );

    //////////////////// instantiation des repository relatifs à la config ///////////////
    accountRepository = new RestAccountRepository(axiosGuestInstance, storeRepository);
    eventRepository = new RestEventRepository(axiosAuthenticatedInstance);
    contactRepository = new RestContactRepository(axiosAuthenticatedInstance);
    timeGridRepository = new RestTimeGridRepository(axiosAuthenticatedInstance);
    siteRepository = new RestSiteRepository(axiosAuthenticatedInstance);
    videoCreditRepository = new RestVideoCreditRepository(axiosAuthenticatedInstance);
    videoInstructionRepository = new RestVideoInstructionRepository(axiosAuthenticatedInstance);
    videoPatrolRepository = new RestVideoPatrolRepository(axiosAuthenticatedInstance);
    troubleshootingMaintenanceRepository = new RestTroubleshootingMaintenanceRepository(axiosAuthenticatedInstance);
    remoteMaintenanceRepository = new RestRemoteMaintenanceRepository(axiosAuthenticatedInstance);
    inspectionRepository = new RestInspectionRepository(axiosAuthenticatedInstance);
    workSiteRepository = new RestWorkSiteRepository(axiosAuthenticatedInstance);
    quoteRepository = new RestQuoteRepository(axiosAuthenticatedInstance);
    configurationRepository = new RestConfigurationRepository(axiosAuthenticatedInstance);
    termsOfServiceRepository = new RestTermsOfServiceRepository(axiosAuthenticatedInstance);
    humanSupervisionRepository = new RestHumanSupervisionRepository(axiosAuthenticatedInstance);
    userRepository = new RestUserRepository(axiosAuthenticatedInstance, storeRepository);
    fileRepository = new RestFileRepository(axiosAuthenticatedInstance);
    userManagementRepository = new RestUserManagementRepository(axiosAuthenticatedInstance);
    informationBandRepository = new RestInformationBandRepository(axiosGuestInstance);

    router.options.base = config.baseUrl;
};

fetch('/env/config.json')
    .then(response => response.json())
    .then(config => {
        Vue.prototype.$config = config;

        InitialiseApp(config);

        new Vue({
            router,
            store,
            vuetify,
            provide: {
                storeRepository: () => storeRepository,
                callListStoreRepository: () => callListStoreRepository,
                responsive: () => responsive,
                menuBus: () => menuBus,
                menuMobileBus: () => menuMobileBus,
                pageNameBus: () => pageNameBus,
                navigationButtonBus: () => navigationButtonBus,
                modifyInformationsContactBus: () => modifyInformationsContactBus,
                tabsNavigationBus: () => tabsNavigationBus,
                tabsNumberNavigationBus: () => tabsNumberNavigationBus,
                toastBus: () => toastBus,
                accountRepository: () => accountRepository,
                eventRepository: () => eventRepository,
                contactRepository: () => contactRepository,
                timeGridRepository: () => timeGridRepository,
                siteRepository: () => siteRepository,
                videoCreditRepository: () => videoCreditRepository,
                videoInstructionRepository: () => videoInstructionRepository,
                videoPatrolRepository: () => videoPatrolRepository,
                troubleshootingMaintenanceRepository: () => troubleshootingMaintenanceRepository,
                remoteMaintenanceRepository: () => remoteMaintenanceRepository,
                inspectionRepository: () => inspectionRepository,
                workSiteRepository: () => workSiteRepository,
                quoteRepository: () => quoteRepository,
                configurationRepository: () => configurationRepository,
                termsOfServiceRepository: () => termsOfServiceRepository,
                humanSupervisionRepository: () => humanSupervisionRepository,
                userRepository: () => userRepository,
                fileRepository: () => fileRepository,
                userManagementRepository: () => userManagementRepository,
                rightRepository: () => rightRepository,
                informationBandRepository: () => informationBandRepository,
                cguBus: () => cguBus,
            },
            render: h => h(App),
        }).$mount('#app');
    });
