export interface HumanSupervision {
    sites: string;
    sitesReferences: string;
    cities: string;
    interventionNumber: string;
    interventionDate: string;
    interventionHour: string;
    callHour: string;
    arrivingHour: string;
    goingHour: string;
    facturationState: string;
    interventionReport: string;
    expanded: boolean;
}

export const emptyHumainSupervision = (): HumanSupervision => ({
    sites: '',
    sitesReferences: '',
    cities: '',
    interventionNumber: '',
    interventionDate: '',
    interventionHour: '',
    callHour: '',
    arrivingHour: '',
    goingHour: '',
    facturationState: '',
    interventionReport: '',
    expanded: false,
});
