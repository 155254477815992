export interface ITroubleshootingMaintenance {
    id: string;
    siteReference: string;
    activity: string;
    siteName: string;
    city: string;
    cause: string;
    responseDate: string;
    interventionDate: string;
    isOngoing: boolean;
    billable: boolean;
}

export const emptyTroubleShootingMaintenance = (): ITroubleshootingMaintenance => ({
    id: '',
    siteReference: '',
    activity: '',
    siteName: '',
    city: '',
    cause: '',
    responseDate: '',
    interventionDate: '',
    isOngoing: false,
    billable: false,
});
