export interface ICompletedInspection {
    id: string;
    contractNumber: string;
    clientNumber: string;
    siteReference: string;
    siteName: string;
    activity: string;
    city: string;
    date: string;
    billable: boolean;
}

export const emptyCompletedInspection = (): ICompletedInspection => ({
    id: '',
    contractNumber: '',
    clientNumber: '',
    siteReference: '',
    siteName: '',
    activity: '',
    city: '',
    date: '',
    billable: false,
});
