export interface IWorkSite {
    id: string;
    siteReference: string;
    siteName: string;
    city: string;
    activity: string;
    expectedStartDate: string;
    expectedEndDate: string;
    progress: number;
}

export const emptyWorkSite = (): IWorkSite => ({
    id: '',
    siteReference: '',
    siteName: '',
    city: '',
    activity: '',
    expectedStartDate: '',
    expectedEndDate: '',
    progress: 0,
});
