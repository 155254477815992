import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import { Component, Inject, Ref, Vue } from 'vue-property-decorator';

import { AccountRepository } from '@/domain/account/AccountRepository';
import { IConfigurationRepository } from '@/domain/configuration/IConfigurationRepository';
import { Routes } from '@/domain/Routes';
import { Theme } from '@/domain/theme/Theme';
import { IconVue } from '@/primary/components/common/icon';
import { FooterVue } from '@/primary/components/footer';
import CguBus from '@/primary/components/footer/CguBus';
import { RestThemeRepository } from '@/secondary/restTheme/RestThemeRepository';

export enum authSteps {
    connection = 'connection',
    telephone = 'telephone',
    code = 'code',
    forgottenPasswordEmail = 'forgottenPasswordEmail',
    forgottenPasswordConfirmed = 'forgottenPasswordConfirmed',
}

@Component({
    components: {
        IconVue,
        FooterVue,
        VueHcaptcha,
    },
})
export default class LoginPageComponent extends Vue {
    @Inject()
    private accountRepository!: () => AccountRepository;

    @Inject()
    private configurationRepository!: () => IConfigurationRepository;

    @Inject()
    private cguBus!: () => CguBus;

    valid = true;
    password = '';
    passwordRules = [(v?: string) => !!v || 'Champs obligatoire'];
    email = '';
    emailRules = [(v?: string) => !!v || 'Champs obligatoire'];
    telephoneNumber = '';
    telephoneNumberRules = /^((\+)33|0)[1-9](\d{2}){4}$/; // +33621639622
    isTelephoneNumberValid = false;

    @Ref('signin') readonly form!: HTMLFormElement;
    doubleAuthentification = false;
    code = '';
    mail = '';
    hasError = false;
    loginError = '';
    errorDoubleAuth = '';
    errorTelephoneNumber = '';
    showPassword = false;
    clickArea1 = '';
    clickArea2 = '';
    clickToCall = '';
    theme!: Theme;
    loginTitle = 'Connexion';
    loginDescription = 'Renseignez votre identifiant et mot de passe pour vous connecter à votre espace.';
    authSteps: authSteps = authSteps.connection;

    captchaCompleted = false;

    created() {
        this.theme = RestThemeRepository.isItesis() ? Theme.ITESIS : Theme.SURWAY;
        if (this.$route.query.theme == 'SURWAY') this.theme = Theme.SURWAY;
    }

    mounted() {
        this.getConfigLinks();
    }

    getConfigLinks(): void {
        this.configurationRepository()
            .getConfigLinks()
            .then(data => {
                if (this.theme == Theme.ITESIS) {
                    this.clickArea1 = data.itesisClickArea1;
                    this.clickArea2 = data.itesisClickArea2;
                    this.clickToCall = data.itesisSupportClickToCall;
                } else {
                    this.clickArea1 = data.surwayClickArea1;
                    this.clickArea2 = data.surwayClickArea2;
                    this.clickToCall = data.surwaySupportClickToCall;
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    checkNumberValidation(): void {
        this.isTelephoneNumberValid = this.telephoneNumberRules.test(this.telephoneNumber);
    }

    signin(): void {
        this.accountRepository()
            .login(this.email, this.password)
            .then(data => {
                this.hasError = false;
                if (data == 0) {
                    this.$router.push({ name: Routes.DashboardPage });
                    this.cguBus().add();
                } else if (data == 1) {
                    this.authSteps = authSteps.code;
                    this.loginTitle = 'Vérification de connexion - SMS envoyé';
                    this.loginDescription = 'Saisissez le code qui vous a été communiqué par SMS afin de valider votre connexion.';
                } else if (data == 2) {
                    this.authSteps = authSteps.telephone;
                    this.loginTitle = 'Compte de démonstration';
                    this.loginDescription = 'Saisissez le numéro de téléphone sur lequel vous souhaitez recevoir le mot de passe.';
                } else if (data == 3) {
                    this.hasError = true;
                    this.loginError =
                        'Authentification impossible : mot de passe expiré. Veuillez prendre contact avec votre administrateur.';
                } else {
                    this.hasError = true;
                    this.loginError = 'Authentification impossible : type de connexion inconnue';
                }
            })
            .catch(() => {
                this.hasError = true;
                this.loginError = 'Authentification impossible : email ou mot de passe incorrect';
            });
    }

    sendTelephoneNumber(): void {
        this.accountRepository()
            .loginPostTelephoneNumber(this.telephoneNumber)
            .then(() => {
                this.authSteps = authSteps.code;
            })
            .catch((error: Error) => {
                this.errorTelephoneNumber = error.message;
            });
    }

    signinDoubleAuthentification(): void {
        this.accountRepository()
            .loginDoubleAuthentification(this.code)
            .then(() => {
                this.$router.push({ name: Routes.DashboardPage });
                this.cguBus().add();
            })
            .catch((error: Error) => {
                this.hasError = true;
                this.loginError = 'Authentification impossible : Code invalide.';
                this.errorDoubleAuth = error.message;
            });
    }

    switchToForgottenPassword(): void {
        this.loginTitle = 'Mot de passe oublié';
        this.loginDescription =
            'Saisissez votre email pour recevoir les instructions qui vous permettront de réinitialiser votre mot de passe.';
        this.authSteps = authSteps.forgottenPasswordEmail;
    }

    sendMail(): void {
        if (this.captchaCompleted) {
            this.accountRepository()
                .forgottenPassword(this.mail.trim())
                .then(() => {
                    this.loginTitle = 'Mot de passe oublié - confirmation d’envoi';
                    this.loginDescription =
                        'Un mail de confirmation vous a été envoyé.</br>' +
                        "Votre demande est valable pendant 15 minutes.</br></br>Veuillez consultez vos mails pour terminer l'opération.";
                    this.authSteps = authSteps.forgottenPasswordConfirmed;
                });
        }
    }

    goToConnect(): void {
        this.authSteps = authSteps.connection;
        this.loginTitle = 'Connexion';
        this.loginDescription = 'Renseignez votre identifiant et mot de passe pour vous connecter à votre espace.';
    }

    captchaCheck(): void {
        this.captchaCompleted = true;
    }
}
