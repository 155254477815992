import { DataTableHeader } from 'vuetify';

export const remoteMaintenanceHeader = (): DataTableHeader[] => [
    {
        text: 'N° demande',
        align: 'start',
        value: 'id',
        sortable: false,
        filterable: true,
    },
    {
        text: 'N° référence',
        align: 'start',
        value: 'reference',
        sortable: false,
        filterable: true,
    },
    { text: 'Statut', value: 'status', sortable: true, filterable: false },
    { text: 'Site', value: 'site', sortable: true, filterable: true },
    { text: 'Ville', value: 'city', sortable: true, filterable: true },
    {
        text: 'Date demande',
        value: 'applicationDate',
        sortable: true,
        filterable: false,
    },
    {
        text: 'Date de clôture',
        value: 'closingDate',
        sortable: true,
        filterable: false,
    },
    { text: 'Demandeur', value: 'requester', sortable: true, filterable: false },
    { text: 'Type de demande', value: 'applicationType', sortable: false, filterable: false },
    { text: 'Description', value: 'description', sortable: false, filterable: true },
];

export const remoteMaintenanceHeaderMobile = (): DataTableHeader[] => [
    {
        text: 'Date demande',
        value: 'applicationDateMobile',
        sortable: false,
        filterable: false,
    },
    { text: 'Description site', value: 'descriptionSite', sortable: false, filterable: true },
    { text: 'Détails', value: 'detail', sortable: false, filterable: false },
];
