import { SiteMap } from '@/domain/site/SiteMap';

export interface SiteMapPrimary {
    reference: string;
    name: string;
    state: string;
    city: string;
    lastAlarmEventDate: string;
    anomalies: string[];
}

export const toSitePrimary = (siteMap: SiteMap): SiteMapPrimary => ({
    ...siteMap,
    anomalies: [siteMap.anomaly],
});
