export interface IRemoteMaintenance {
    id: string;
    reference: string;
    site: string;
    city: string;
    status: string;
    applicationDate: string;
    closingDate: string;
    requester: string;
    applicationType: string;
    description: string;
}

export const emptyRemoteMaintenance = (): IRemoteMaintenance => ({
    id: '',
    reference: '',
    site: '',
    city: '',
    status: '',
    applicationDate: '',
    closingDate: '',
    requester: '',
    applicationType: '',
    description: '',
});
