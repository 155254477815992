import { AxiosInstance, AxiosResponse } from 'axios';

import { InformationBandRepository } from '@/domain/informationBand/InformationBandRepository';

export class RestInformationBandRepository implements InformationBandRepository {
    constructor(private axiosInstance: AxiosInstance) {}

    getMessage(): Promise<string> {
        return this.axiosInstance.get('/MaintenanceBanner/BannerInfo').then((result: AxiosResponse<{ Message: string }>) => {
            if (result.status === 204) return '';
            return result.data.Message;
        });
    }
}
