import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { siteHeaderMobile, sitesHeader } from '@/domain/header/siteHeader';
import { ExportSiteParam } from '@/domain/site/ExportSiteParams';
import { emptySiteDetails, SiteDetails } from '@/domain/site/siteDetails/SiteDetails';
import { SiteMap } from '@/domain/site/SiteMap';
import { SiteRepository } from '@/domain/site/SiteRepository';
import { IconVue } from '@/primary/components/common/icon';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { SiteStateVue } from '@/primary/components/siteState';
import { SiteMapPrimary, toSitePrimary } from '@/primary/pages/site/SiteMapPrimary';
import { RightMixin } from '@/primary/utils/rights/RightMixin';

@Component({
    components: {
        IconVue,
        SiteStateVue,
    },
})
export default class SitePage extends mixins(RightMixin) {
    @Inject()
    private siteRepository!: () => SiteRepository;

    @Inject()
    private pageNameBus!: () => PageNameBus;

    dataSite: SiteMapPrimary[] = [];
    headerMobile: DataTableHeader[] = siteHeaderMobile();
    headerNormal: DataTableHeader[] = sitesHeader();
    dataDownload = true;
    search = '';
    mobileContentHeader = false;
    sizePhone = 767;
    windowWidth: number = window.innerWidth;
    siteSelected: string[] = [];
    etatSystemSelected: string[] = [];
    isDetailsSiteModalOpened = false;
    sitesFilter: string[] = [];
    stateFilter: string[] = [];
    siteDetails: SiteDetails = emptySiteDetails();

    globalHeader: DataTableHeader[] = [
        { text: 'Etat', value: 'state' },
        { text: 'Site', value: 'name' },
        { text: 'Ville', value: 'city' },
        { text: 'Alertes', value: 'anomalies' },
    ];

    detailsHeader: DataTableHeader[] = [
        { text: 'Référence site', value: 'siteReference' },
        { text: 'Raison sociale', value: 'socialReason' },
        { text: 'Adresse', value: 'address' },
        { text: 'Code postal', value: 'postalCode' },
        { text: 'Ville', value: 'city' },
    ];

    adresseHeader: DataTableHeader[] = [
        { text: 'Adresse', value: 'address' },
        { text: 'Code postal', value: 'postalCode' },
        { text: 'Ville', value: 'city' },
    ];

    inChargeHeader: DataTableHeader[] = [
        { text: 'Responsable', value: 'inCharge' },
        { text: 'Téléphones', value: 'phone' },
        { text: 'Fax', value: 'fax' },
        { text: 'Adresse email', value: 'mail' },
    ];

    contratHeader: DataTableHeader[] = [
        { text: 'Numéro de client', value: 'clientNumber' },
        { text: 'Date de souscription', value: 'subscriptionDate' },
        { text: 'N° Contrat Télésurveillance', value: 'remoteSecurityContract' },
        { text: 'N° Contrat Deltaservice', value: 'remoteMonitoringContract' },
    ];

    equipmentHeader: DataTableHeader[] = [
        { text: 'Modèle', value: 'model' },
        { text: 'Marque', value: 'brand' },
    ];

    get sitesFilterOrdered() {
        return this.sitesFilter.sort().sort((a, b) => {
            const hasA = this.siteSelected.find(site => site === a) !== undefined;
            const hasB = this.siteSelected.find(site => site === b) !== undefined;
            return +hasB - +hasA;
        });
    }

    mounted() {
        this.pageNameBus().add('Mes Sites');
        this.siteRepository()
            .getSite()
            .then(this.fromDomain)
            .catch(error => {
                console.log(error);
            });
        // resize table for mobile
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    }

    // is mobile change name header
    get getMobileHeader() {
        if (this.isMobileSize) {
            return this.headerMobile;
        } else {
            return this.headerNormal;
        }
    }

    get isMobileSize() {
        return this.windowWidth <= this.sizePhone;
    }

    // open modal detail
    showDetailsSiteModal(siteReference: string) {
        this.isDetailsSiteModalOpened = true;
        this.siteRepository()
            .getSiteDetails(siteReference)
            .then(siteDetails => {
                this.siteDetails = siteDetails;
            });
    }

    // close modal
    closeDetailsSiteModal() {
        this.isDetailsSiteModalOpened = false;
    }

    // set dynamique calss for table
    setClass() {
        return 'history--item ';
    }

    // exports data
    exportData(fileType: string) {
        this.siteRepository()
            .getExport(fileType, this.toExportSiteParams())
            .catch(error => {
                console.log(error);
            });
    }

    // init query data
    private toExportSiteParams(): ExportSiteParam {
        const siteParam: string[] = [];
        this.updateData.forEach(e => {
            siteParam.push(e.reference);
        });
        const eventParams: ExportSiteParam = { sites: siteParam };
        return eventParams;
    }

    // init all filter and add data in datasite
    private fromDomain(sites: SiteMap[]) {
        this.dataSite = this.siteToPrimary(sites);
        this.createSiteFilter(this.dataSite);
        this.createStateFilter(this.dataSite);
        this.dataDownload = false;
    }

    private siteToPrimary(sites: SiteMap[]) {
        const concatSiteMap: Map<string, SiteMapPrimary> = new Map();
        sites.forEach(site => {
            const primarySite = toSitePrimary(site);
            const alreadyInMap = concatSiteMap.get(site.reference);
            if (alreadyInMap) primarySite.anomalies.push(...alreadyInMap.anomalies);
            concatSiteMap.set(site.reference, primarySite);
        });
        concatSiteMap.forEach(site => (site.anomalies = site.anomalies.reverse()));
        return Array.from(concatSiteMap.values());
    }

    // init filter state
    private createStateFilter(states: SiteMapPrimary[]) {
        states.forEach(etat => {
            if (typeof etat.state !== 'undefined') {
                this.stateFilter.push(etat.state);
            }
        });
        this.stateFilter = [...new Set(this.stateFilter)];
    }

    // init filter site
    private createSiteFilter(sites: SiteMapPrimary[]) {
        sites.forEach(site => {
            this.sitesFilter.push(site.name);
        });
        this.sitesFilter = [...new Set(this.sitesFilter)];
    }

    // updtae state after item selected
    private stateUpdated(event: SiteMapPrimary) {
        if (this.etatSystemSelected.length > 0) {
            return this.etatSystemSelected.indexOf(event.state) > -1;
        }
        return true;
    }

    // update site filter
    private siteUpdated(event: SiteMapPrimary) {
        if (this.siteSelected.length > 0) {
            return this.siteSelected.indexOf(event.name) > -1;
        }
        return true;
    }

    //return all data sites after update filter
    get updateData() {
        return this.dataSite.filter(event => {
            return this.siteUpdated(event) && this.stateUpdated(event);
        });
    }
}
