import { EventHistory, EventHistoryMediaDetail } from '@/domain/event/Event';
import { EventType } from '@/domain/event/EventType';
import { dateFormat } from '@/primary/utils/Filters/DateFormat';
import { stringCleaner } from '@/secondary/global/StringCleaner';
import { toEventHistoryMediaDetail } from '@/secondary/restEvent/RestEventAttachementDetail';
import { RestEventAttachment, toEventHistoryMedia } from '@/secondary/restEvent/RestEventAttachment';

export interface RestEvent {
    Id: string;
    SiteName: string;
    Description: string;
    OccurredOn: string;
    Type: number;
    Attachment: RestEventAttachment | null;
}

export const toEvent = (restEvent: RestEvent): EventHistory => ({
    id: restEvent.Id,
    site: stringCleaner(restEvent.SiteName),
    description: restEvent.Description,
    date: dateFormat(restEvent.OccurredOn),
    type: EventType[restEvent.Type],
    media: restEvent.Attachment ? toEventHistoryMedia(restEvent.Attachment) : undefined,
});

export const toEventDetails = (restEvent: RestEvent): EventHistoryMediaDetail[] => {
    return restEvent.Attachment?.Details?.map(toEventHistoryMediaDetail)!;
};
