import { DataTableHeader } from 'vuetify';

export const sitesHeader = (): DataTableHeader[] => [
    {
        text: 'Etat du systeme',
        align: 'start',
        value: 'state',
        sortable: true,
        filterable: false,
    },
    { text: 'Site(s)', value: 'name', sortable: true, filterable: true },
    { text: 'Ville', value: 'city', sortable: true, filterable: true },
    { text: 'Alertes', value: 'anomalies', sortable: true, filterable: false },
    {
        text: 'Détails du site',
        value: 'actions',
        sortable: false,
        filterable: false,
        align: 'center',
    },
];
export const siteHeaderMobile = (): DataTableHeader[] => [
    {
        text: 'Etat du système',
        align: 'start',
        value: 'state',
        sortable: false,
        filterable: false,
    },
    { text: 'Site alerte(s) récente(s)', value: 'name', sortable: false, filterable: true },
    {
        text: 'Détails',
        value: 'actions',
        sortable: false,
        filterable: false,
        align: 'center',
    },
];
