import moment from 'moment';

export function dateFormat(date: string) {
    return moment(new Date(date)).locale('fr').format('DD/MM/YYYY HH[h]mm');
}

export function dateFormatShort(date: string) {
    return moment(new Date(date)).locale('fr').format('DD/MM HH[h]mm');
}

export function datePickerFormat(date: string) {
    return moment(new Date(date)).locale('fr').format('L');
}

export const formatDate = (date: string) => moment(date, 'DD/MM/YYYY HH[h]mm').toISOString();
