import { DataTableHeader } from 'vuetify';

export const workSiteHeader = (): DataTableHeader[] => [
    { text: 'N° Chantier', align: 'start', value: 'id', sortable: false, filterable: true },
    { text: 'N° de site', value: 'siteReference', sortable: false, filterable: true },
    { text: 'Site(s)', value: 'siteName', sortable: true, filterable: true },
    { text: 'Ville(s)', value: 'city', sortable: true, filterable: true },
    { text: 'Date de début prévue', value: 'expectedStartDate', sortable: true, filterable: false },
    { text: 'Date de fin prévue', value: 'expectedEndDate', sortable: true, filterable: false },
    { text: 'Avancement', value: 'progress', sortable: true, filterable: false },
    { text: 'Activité', value: 'activity', sortable: true, filterable: false },
    { text: 'Détail', value: 'detail', sortable: false, filterable: false },
];
export const workSiteModal = (): DataTableHeader[] => [
    { text: 'N° Chantier', align: 'start', value: 'id', sortable: false, filterable: true },
    { text: 'N° de site', value: 'siteReference', sortable: false, filterable: true },
    { text: 'Site(s)', value: 'siteName', sortable: true, filterable: true },
    { text: 'Ville(s)', value: 'city', sortable: true, filterable: true },
    { text: 'Date de début prévue', value: 'expectedStartDate', sortable: true, filterable: false },
    { text: 'Date de fin prévue', value: 'expectedEndDate', sortable: true, filterable: false },
    { text: 'Avancement', value: 'progress', sortable: true, filterable: false },
    { text: 'Activité', value: 'activity', sortable: true, filterable: false },
];

export const workSiteHeaderMobile = (): DataTableHeader[] => [
    { text: 'Date début', value: 'expectedStartDate', sortable: false, filterable: true },
    { text: 'Date fin', value: 'expectedEndDate', sortable: false, filterable: true },
    { text: 'Site', value: 'siteName', sortable: false, filterable: true },
    { text: 'Détail', value: 'detailMobile', sortable: false, filterable: false },
];
