import { AxiosError } from 'axios';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component, Inject } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { workSiteHeader, workSiteHeaderMobile, workSiteModal } from '@/domain/header/workSiteHeader';
import { ExportWorksitesParam } from '@/domain/workSite/ExportWorkSitesParam';
import { emptyWorkSite, IWorkSite } from '@/domain/workSite/IWorkSite';
import { IWorkSiteRepository } from '@/domain/workSite/IWorkSiteRepository';
import { IconVue } from '@/primary/components/common/icon';
import PageNameBus from '@/primary/components/navbar/PageNameBus';
import { RightMixin } from '@/primary/utils/rights/RightMixin';

@Component({
    components: {
        IconVue,
    },
})
export default class WorkSitePage extends mixins(RightMixin) {
    @Inject()
    private pageNameBus!: () => PageNameBus;

    @Inject()
    private workSiteRepository!: () => IWorkSiteRepository;

    workSiteHeader: DataTableHeader[] = workSiteHeader();
    workSiteHeaderModal: DataTableHeader[] = workSiteModal();
    workSiteHeaderMobile: DataTableHeader[] = workSiteHeaderMobile();

    startDateOpen = false;
    startDateOpenMobile = false;
    endDateOpen = false;
    endDateOpenMobile = false;
    search = '';

    workSites: IWorkSite[] = [];
    sites: string[] = [];
    selectedSites: string[] = [];
    cities: string[] = [];
    selectedCities: string[] = [];
    activities: string[] = [];
    selectedActivities: string[] = [];
    progressRange: number[] = [0, 100];
    progressMin = 0;
    progressMax = 100;

    endRangeDates = [];
    startRangeDates = [];

    sizePhone = 600;
    windowWidth = window.innerWidth;
    showModal = false;
    selectedItem = emptyWorkSite();

    get updateData() {
        return this.workSites.filter(workSite => {
            return (
                this.siteUpdated(workSite) &&
                this.cityUpdated(workSite) &&
                this.activityUpdated(workSite) &&
                this.startDateUpdated(workSite) &&
                this.endDateUpdated(workSite) &&
                this.progressUpdated(workSite)
            );
        });
    }

    get formatCustomEndRangeDates() {
        if (this.endRangeDates.length > 0) {
            const dates = this.endRangeDates.map(date => {
                return moment(new Date(date)).locale('fr').format('DD/MM/YY');
            });
            return dates.join(' - ');
        }
        return [];
    }

    get formatCustomStartRangeDates() {
        if (this.startRangeDates.length > 0) {
            const dates = this.startRangeDates.map(date => {
                return moment(new Date(date)).locale('fr').format('DD/MM/YY');
            });
            return dates.join(' - ');
        }
        return [];
    }

    get getHeader() {
        if (this.isMobile) {
            return this.workSiteHeaderMobile;
        } else {
            return this.workSiteHeader;
        }
    }

    get isMobile() {
        return this.windowWidth <= this.sizePhone;
    }

    get siteOrdered() {
        return this.sites
            .sort((a, b) => a.localeCompare(b))
            .sort((a, b) => {
                const hasA = this.selectedSites.find(site => site === a) !== undefined;
                const hasB = this.selectedSites.find(site => site === b) !== undefined;
                return +hasB - +hasA;
            });
    }

    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });

        this.pageNameBus().add('Mes Chantiers');

        this.workSiteRepository()
            .getWorkSites()
            .then(data => {
                this.fromDomain(data);
            })
            .catch((error: AxiosError) => {
                console.log(error);
            });
    }

    openDetailModal(item: IWorkSite) {
        this.showModal = true;
        this.selectedItem = item;
    }

    closeDetailModal() {
        this.showModal = false;
        this.selectedItem = emptyWorkSite();
    }

    exportData(type: string) {
        this.workSiteRepository().getExport(type, this.toExportWorkSitesParamsParams());
    }

    getDetailsReport(worksiteNumber: string) {
        this.workSiteRepository()
            .getWorkSiteDetailsReport(worksiteNumber)
            .catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    private fromDomain(workSites: IWorkSite[]) {
        this.workSites = workSites;
        this.createSiteFilter(workSites);
        this.createCityFilter(workSites);
        this.createActivityFilter(workSites);
    }

    private toExportWorkSitesParamsParams(): ExportWorksitesParam {
        let workSitesParams: ExportWorksitesParam = {
            Sites: this.selectedSites,
            Cities: this.selectedCities,
            Activities: this.selectedActivities,
            FromProgress: this.progressRange[0] / 100,
            ToProgress: this.progressRange[1] == 100 ? undefined : this.progressRange[1] / 100,
        };
        if (this.startRangeDates.length > 0) {
            workSitesParams = {
                ...workSitesParams,
                FromExpectedStartDate: new Date(this.startRangeDates[0]).toISOString(),
                ToExpectedStartDate: new Date(this.startRangeDates[1]).toISOString(),
            };
        }
        if (this.endRangeDates.length > 0) {
            workSitesParams = {
                ...workSitesParams,
                FromExpectedEndDate: new Date(this.endRangeDates[0]).toISOString(),
                ToExpectedEndDate: new Date(this.endRangeDates[1]).toISOString(),
            };
        }

        return workSitesParams;
    }

    private siteUpdated(workSite: IWorkSite) {
        if (this.selectedSites.length > 0) return this.selectedSites.indexOf(workSite.siteName) > -1;
        return true;
    }

    private cityUpdated(workSite: IWorkSite) {
        if (this.selectedCities.length > 0) return this.selectedCities.indexOf(workSite.city) > -1;
        return true;
    }

    private activityUpdated(workSite: IWorkSite) {
        if (this.selectedActivities.length > 0) return this.selectedActivities.indexOf(workSite.activity) > -1;
        return true;
    }

    private progressUpdated(workSite: IWorkSite) {
        return workSite.progress > this.progressRange[0] && workSite.progress < this.progressRange[1];
    }

    private createSiteFilter(workSites: IWorkSite[]) {
        workSites.forEach(workSite => {
            this.sites.push(workSite.siteName);
        });
        this.sites = [...new Set(this.sites)];
    }

    private createCityFilter(workSites: IWorkSite[]) {
        workSites.forEach(workSite => {
            this.cities.push(workSite.city);
        });
        this.cities = [...new Set(this.cities)];
    }

    private createActivityFilter(workSites: IWorkSite[]) {
        workSites.forEach(workSite => {
            this.activities.push(workSite.activity);
        });
        this.activities = [...new Set(this.activities)];
    }

    private startDateUpdated(workSite: IWorkSite) {
        if (this.startRangeDates.length > 0)
            return moment(new Date(workSite.expectedStartDate).toDateString()).isBetween(
                new Date(this.startRangeDates[0]).toDateString(),
                new Date(this.startRangeDates[1]).toDateString()
            );
        if (this.startRangeDates.length > 0)
            return moment(new Date(workSite.expectedStartDate).toDateString()).isSameOrAfter(
                new Date(this.startRangeDates[0]).toDateString()
            );
        if (this.startRangeDates.length > 1)
            return moment(new Date(workSite.expectedStartDate).toDateString()).isSameOrBefore(
                new Date(this.startRangeDates[1]).toDateString()
            );
        return true;
    }

    private endDateUpdated(workSite: IWorkSite) {
        if (this.endRangeDates.length > 0)
            return moment(new Date(workSite.expectedEndDate).toDateString()).isBetween(
                new Date(this.endRangeDates[0]).toDateString(),
                new Date(this.endRangeDates[1]).toDateString()
            );
        if (this.endRangeDates.length > 0)
            return moment(new Date(workSite.expectedEndDate).toDateString()).isSameOrAfter(new Date(this.endRangeDates[0]).toDateString());
        if (this.endRangeDates.length > 1)
            return moment(new Date(workSite.expectedEndDate).toDateString()).isSameOrBefore(new Date(this.endRangeDates[1]).toDateString());
        return true;
    }
}
