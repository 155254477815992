import { Component, Inject, Vue } from 'vue-property-decorator';

import { InformationBandRepository } from '@/domain/informationBand/InformationBandRepository';
import { IconVue } from '@/primary/components/common/icon';

@Component({
    components: { IconVue },
})
export default class InformationBandComponent extends Vue {
    isOpen = false;
    bandText = '';

    @Inject()
    private informationBandRepository!: () => InformationBandRepository;

    mounted() {
        this.informationBandRepository()
            .getMessage()
            .then(message => {
                console.log(message);
                this.isOpen = message.length > 0;
                this.bandText = message;
            });
    }

    isClientLayout = () => this.$route.path.includes('/client');

    closeInformationBand() {
        this.isOpen = false;
    }
}
