export interface IQuote {
    id: number;
    siteReference: number;
    siteName: string;
    city: string | null;
    activity: string;
    date: string;
    cost: number | null;
}

export const emptyQuote = (): IQuote => ({
    id: 0,
    siteReference: 0,
    siteName: '',
    city: null,
    activity: '',
    date: '',
    cost: null,
});
