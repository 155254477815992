import { DataTableHeader } from 'vuetify';

export const quotesHeader = (): DataTableHeader[] => [
    { text: 'N° Devis', align: 'start', value: 'id', sortable: false, filterable: true },
    { text: 'N° de site', value: 'siteReference', sortable: false, filterable: true },
    { text: 'Date', value: 'date', sortable: true, filterable: false },
    { text: 'Site(s)', value: 'siteName', sortable: true, filterable: true },
    { text: 'Ville(s)', value: 'city', sortable: true, filterable: true },
    { text: 'Activité', value: 'activity', sortable: true, filterable: false },
    { text: 'Montant', value: 'cost', sortable: true, filterable: true },
    { text: 'Détail', value: 'detail', sortable: false, filterable: false },
];

export const quotesHeaderModal = (): DataTableHeader[] => [
    { text: 'N° Devis', align: 'start', value: 'id' },
    { text: 'N° de site', value: 'siteReference' },
    { text: 'Date', value: 'date' },
    { text: 'Site(s)', value: 'siteName' },
    { text: 'Ville(s)', value: 'city' },
    { text: 'Activité', value: 'activity' },
    { text: 'Montant', value: 'cost' },
];

export const quotesHeaderMobile = (): DataTableHeader[] => [
    { text: 'Date', value: 'date', sortable: false, filterable: false },
    { text: 'Site', value: 'siteName', sortable: false, filterable: true },
    { text: 'Montant', value: 'cost', sortable: false, filterable: true },
    { text: 'Détail', value: 'detailMobile', sortable: false, filterable: false },
];
